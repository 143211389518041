<template>
  <div class="main mx-2 py-2 border-bottom">
    <strong>{{ index + 1 }}.</strong>
    <div class="d-flex flex-column pb-3">
      <b-button
        v-b-tooltip.hover.ds800.dh000
        class="icon-button"
        size="sm"
        variant="outline-info"
        title="Presunúť položku o jeden hore"
        @click="$emit('moveUp')"
      >
        <font-awesome-icon :icon="['fas', 'arrow-up']" />
      </b-button>
      <b-button
        v-b-tooltip.hover.ds800.dh000
        class="icon-button mb-2"
        size="sm"
        variant="outline-info"
        title="Presunúť položku o jeden dole"
        @click="$emit('moveDown')"
      >
        <font-awesome-icon :icon="['fas', 'arrow-down']" />
      </b-button>
      <b-button
        v-if="isImage"
        v-b-tooltip.hover.ds800.dh000
        class="icon-button mb-4"
        size="sm"
        variant="outline-info"
        title="Otočiť obrázok doprava"
        @click="$emit('rotateImage')"
      >
        <font-awesome-icon :icon="['fas', 'redo']" />
      </b-button>
      <b-button
        v-b-tooltip.hover.ds800.dh000
        class="icon-button mt-auto"
        size="sm"
        variant="outline-danger"
        title="Odstrániť súbor"
        @click="$emit('remove')"
      >
        <font-awesome-icon :icon="['fas', 'trash']" />
      </b-button>
    </div>
    <div ref="fileContainer">
      <div v-if="isImage" :style="containerStyle" class="image-container">
        <img
          :src="imageUrl"
          :style="imageStyle"
          alt="Uploaded image"
          @load="imageLoaded"
        />
      </div>
      <div v-else>
        <h2>
          <font-awesome-icon :icon="['fas', 'file-pdf']" />
          <span class="text-muted ml-3">PDF</span>
        </h2>
      </div>
      <p>{{ file.name }}</p>
    </div>
  </div>
</template>

<script>
import constants from "@/constants";

const imageTypes = [constants.mimeTypes.PNG, constants.mimeTypes.JPG];

export default {
  name: "SubmitFileEntry",
  props: {
    file: {
      type: File,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    angle: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      imgWidth: 50,
      imgHeight: 50,
    };
  },
  computed: {
    isImage() {
      return imageTypes.some((t) => t === this.file.type);
    },
    imageUrl() {
      if (!this.isImage) return "";
      return URL.createObjectURL(this.file);
    },
    largestDimention() {
      return Math.max(this.imgWidth, this.imgHeight);
    },
    actualWidth() {
      const a = this.degToRad(this.angle);
      return Math.abs(
        this.imgWidth * Math.cos(a) + this.imgHeight * Math.sin(a),
      );
    },
    actualHeight() {
      const a = this.degToRad(this.angle);
      return Math.abs(
        this.imgWidth * Math.sin(a) + this.imgHeight * Math.cos(a),
      );
    },
    containerStyle() {
      let style = `width: ${this.actualWidth}px; `;
      style += `height: ${this.actualHeight}px; `;
      return style;
    },
    imageStyle() {
      const d = this.largestDimention;
      let style = `width: ${d}px; min-width: ${d}px;`;
      style += `height: ${d}px; min-height: ${d}px;`;
      style += `transform: rotate(${this.angle}deg);`;
      return style;
    },
  },
  methods: {
    imageLoaded(e) {
      this.imgWidth = e.target.naturalWidth;
      this.imgHeight = e.target.naturalHeight;

      const max = Math.max(this.imgWidth, this.imgHeight);
      const maxAllowed = this.$refs.fileContainer.clientWidth;
      if (max <= maxAllowed) return;
      this.imgWidth *= maxAllowed / max;
      this.imgHeight *= maxAllowed / max;
    },
    degToRad(deg) {
      return (deg * Math.PI) / 180;
    },
  },
};
</script>

<style scoped>
.main {
  display: grid;
  grid-template-columns: min-content min-content auto;
  grid-template-rows: 100%;
  gap: 1em;
}

.border-bottom {
  border-bottom: 1px solid var(--dark);
}

.icon-button {
  width: 2.5em;
  height: 2.5em;
}

img {
  object-fit: scale-down;
  pointer-events: none;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
