import { render, staticRenderFns } from "./RoundDetail.vue?vue&type=template&id=f3bb99ee&scoped=true&"
import script from "./RoundDetail.vue?vue&type=script&lang=js&"
export * from "./RoundDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f3bb99ee",
  null
  
)


    import installComponents from "!/Users/jurajpetras/dev/pmat/web/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BCol, BRow, BContainer, BJumbotron} from 'bootstrap-vue'
    installComponents(component, {BCol, BRow, BContainer, BJumbotron})
    


    import installDirectives from "!/Users/jurajpetras/dev/pmat/web/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installDirectives.js"
    import {VBPopover} from 'bootstrap-vue'
    installDirectives(component, {'b-popover': VBPopover})
    

export default component.exports