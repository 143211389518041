<template>
  <b-list-group v-b-scrollspy="{ offset: -scrollConfig.offset }">
    <b-list-group-item
      v-for="item of items"
      :key="item.scrollId"
      v-scroll-to="{
        el: item.scrollId,
        ...scrollConfig,
      }"
      :href="item.scrollId"
    >
      <span :class="{ 'font-weight-bold': item.highlighted }">
        {{ item.text }}
      </span>
      <FavoriteStar v-if="item.highlighted" />
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import FavoriteStar from "@/components/utils/FavoriteStar.vue";

export default {
  name: "ScrollNavigator",
  components: {
    FavoriteStar,
  },
  mixins: [],
  props: {
    items: {
      type: Array,
      required: true,
    },
    scrollConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {
    this.$root.$on("bv::scrollspy::activate", this.onScrollspyActivate);
  },
  methods: {
    onScrollspyActivate(target) {
      // TODO find a way to catch specifically the redundant navigation error
      this.$router.replace({ hash: target }).catch(() => {});
    },
  },
};
</script>

<style scoped></style>
