import { render, staticRenderFns } from "./ProblemDetail.vue?vue&type=template&id=3fdc722a&scoped=true&"
import script from "./ProblemDetail.vue?vue&type=script&lang=js&"
export * from "./ProblemDetail.vue?vue&type=script&lang=js&"
import style0 from "./ProblemDetail.vue?vue&type=style&index=0&id=3fdc722a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fdc722a",
  null
  
)


    import installComponents from "!/Users/jurajpetras/dev/pmat/web/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BCardTitle, BCol, BRow, BCardText, BCardBody, BCard, BButton, BButtonGroup, BCollapse} from 'bootstrap-vue'
    installComponents(component, {BCardTitle, BCol, BRow, BCardText, BCardBody, BCard, BButton, BButtonGroup, BCollapse})
    


    import installDirectives from "!/Users/jurajpetras/dev/pmat/web/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installDirectives.js"
    import {VBPopover, VBModal, VBToggle} from 'bootstrap-vue'
    installDirectives(component, {'b-popover': VBPopover, 'b-modal': VBModal, 'b-toggle': VBToggle})
    

export default component.exports