<template>
  <div class="position-relative">
    <slot class="test" />
    <div
      :class="{
        'cover-visible': $refs.uploader && $refs.uploader.dropActive,
        highlited: hovering,
      }"
      class="cover fill-parent"
      @dragenter="dragEnter"
      @dragleave="dragLeave"
    >
      <div class="background fill-parent" />
      <h1>
        {{ title }}
        <br />
        <font-awesome-icon :icon="['fas', 'file-upload']" class="icon" />
      </h1>
    </div>
    <vue-upload-component
      ref="uploader"
      v-model="files"
      name="feedback-file"
      :accept="accept.join(',')"
      :multiple="true"
      :drop="enabled"
      :drop-directory="false"
      @input="dropped"
    />
  </div>
</template>

<script>
import constants from "@/constants";
import VueUploadComponent from "vue-upload-component";

export default {
  name: "DropZone",
  components: {
    VueUploadComponent,
  },
  mixins: [],
  props: {
    enabled: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "Potiahni sem súbor!",
    },
    accept: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      constants,
      files: [],
      hovering: false,
    };
  },
  computed: {},
  created() {
    window.addEventListener("drop", this.windowDropped);
  },
  destroyed() {
    window.removeEventListener("dragenter", this.windowDropped);
  },
  mounted() {},
  methods: {
    windowDropped(e) {
      e.preventDefault();
    },
    dropped() {
      this.hovering = false;
      if (this.files.length <= 0) return;
      if (this.allowed(this.files)) {
        this.$emit(
          "dropped",
          this.files.map((f) => f.file),
        );
      } else {
        this.$emit("wrongFileTypes");
      }
      this.files = [];
    },
    allowed(files) {
      if (this.accept.length <= 0) return true;
      return !files.some((f) => !this.accept.includes(f.file.type));
    },
    dragEnter() {
      this.hovering = true;
    },
    dragLeave() {
      this.hovering = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.fill-parent {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.cover {
  --speed: 0.25s;
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  pointer-events: none;
  transition: opacity 0.3 ease-in-out;
  opacity: 0;
  &.cover-visible {
    pointer-events: all;
    opacity: 1;
  }

  & > .background {
    background-color: var(--dark);
    opacity: 0.5;
    transition: opacity var(--speed) ease-in-out;
  }
  h1 {
    position: relative;
    pointer-events: none;
    transform: translateY(0.5em);
    transition: transform var(--speed) ease-in-out;
    text-align: center;
    .icon {
      opacity: 0;
      transform: scale(0.5);
      transition: all var(--speed) ease-in-out;
    }
  }

  &.highlited {
    .background {
      opacity: 0.75;
    }

    h1 {
      transform: scale(1.2) translateY(0em);
      .icon {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
}
</style>
