<!-- eslint-disable max-lines -->
<template>
  <DropZone
    :enabled="dropZoneEnabled"
    :title="'Sem potiahni úlohu ' + problem.number"
    :accept="constants.acceptedSubmitFormats"
    @dropped="uploadFiles"
    @wrongFileTypes="wrongFileTypes"
  >
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col lg="auto">
            <b-card-title
              :class="{
                'text-muted': highlightProblems && !problem.recommendedForMe,
              }"
            >
              {{ problem.number }}. {{ problem.name }}
              <FavoriteStar
                v-if="problem.recommendedForMe"
                v-b-popover.hover="'Úloha je určená pre tvoju kategóriu.'"
              />
              <font-awesome-icon
                v-if="isInteractive"
                v-b-popover.hover.top="
                  'Uloha je interaktívna! Budeš ju riešiť online!'
                "
                class="ml-2"
                :icon="['fas', 'gamepad']"
              />
            </b-card-title>
          </b-col>
          <b-col class="text-right mb-3">
            <CategoryBadge
              v-for="category of visibleCategories"
              :key="category.name"
              :tooltip="tooltipText(problem, category)"
              :recommended="category.recommended"
            >
              {{ category.name }}
            </CategoryBadge>
          </b-col>
        </b-row>
        <b-card-text>
          <advanced-markdown doc-id="test" :source="problem.statement" />
        </b-card-text>
      </b-card-body>
    </b-card>

    <b-row class="my-2">
      <!-- points and submitted solution / evaluated solution -->
      <b-col xl="auto" order-xl="3" class="mt-2">
        <LoadingSpinner :is-loaded="submitLoaded">
          <b-row v-if="submit">
            <b-col v-if="submit.evaluation">
              <b-button-group
                ref="evaluationButton"
                v-b-popover.hover.top="
                  !submit.evaluation.feedback &&
                  !isInteractive &&
                  'Opravené riešenie opravovateľ zatiaľ nenahral.'
                "
              >
                <b-button
                  v-if="!isInteractive"
                  variant="success"
                  block
                  :disabled="!submit.evaluation.feedback"
                  @click="downloadEvaluation()"
                >
                  <span
                    :class="{ 'icon-indicator': !submit.evaluation.seen }"
                    class="mr-1"
                  >
                    <font-awesome-icon :icon="['fas', 'comment']" />
                  </span>
                  Ohodnotené riešenie
                </b-button>
                <b-button
                  v-if="submit.evaluation.score"
                  style="white-space: nowrap"
                  variant="outline-success"
                  :disabled="!submit.evaluation.feedback"
                  @click="downloadEvaluation()"
                >
                  {{ submit.evaluation.score }} b
                </b-button>
              </b-button-group>

              <ThumbnailTooltip
                v-if="submit.evaluation.thumbnail"
                :url="submit.evaluation.thumbnail"
                :target="() => $refs['evaluationButton']"
                title="Náhľad súboru"
              />
            </b-col>

            <b-col v-else>
              <b-button
                ref="submitButton"
                variant="outline-success"
                block
                @click="downloadSubmit()"
              >
                <font-awesome-icon :icon="['fas', 'envelope']" /> Odovzdané
                riešenie
              </b-button>

              <ThumbnailTooltip
                v-if="submit.thumbnail"
                :url="submit.thumbnail"
                :target="() => $refs['submitButton']"
                title="Náhľad súboru"
              />
            </b-col>
          </b-row>
        </LoadingSpinner>
      </b-col>

      <!-- submit form / login button -->
      <b-col v-if="isInteractive" xl="auto" order-xl="4" class="mt-2">
        <b-button variant="primary" :href="`/interactive/${problem.id}/`" block>
          Otvoriť interaktívnu úlohu
        </b-button>
      </b-col>
      <b-col v-else-if="allowUpload" xl="auto" order-xl="4" class="mt-2">
        <ProblemSubmitUpload
          v-if="$root.isAuthenticated && $root.stateLoaded"
          :problem-id="problem.id"
          :problem-allowed-for-me="problem.allowedForMe"
          :previous-submit="!!submit"
          :analytics-problem-name="analyticsProblemName"
          @submitted="onSubmitUpload()"
          @hookUploadFiles="(e) => (uploadFiles = e)"
          @modalToggled="(e) => $emit('modalToggled', e)"
        />
        <b-button v-else v-b-modal.login-modal variant="outline-primary" block>
          Prihlásiť sa a odovzdať
        </b-button>
      </b-col>

      <!-- our solution collapse -->
      <b-col xl="auto" class="mt-2">
        <div v-if="problem.solution">
          <DangerButton
            v-b-toggle="`solution-${problem.id}`"
            variant="primary"
            :danger="!round.solutions_visible"
            danger-tooltip="Toto riešenie nie je zverejnené pre riešiteľov!"
          >
            Vzorové riešenie
            <font-awesome-icon
              class="ml-1"
              :icon="[
                'fas',
                solutionVisible ? 'angle-double-up' : 'angle-double-down',
              ]"
            />
          </DangerButton>
          <b-button
            v-if="round.solutions_visible && problem.video_solution"
            variant="secondary"
            :href="problem.video_solution"
            target="_blank"
            rel="noopener"
            class="ml-2"
          >
            <font-awesome-icon :icon="['fas', 'film']" class="mr-1" />
            Video riešenie
          </b-button>
        </div>
      </b-col>
      <b-col class="m-0 p-0">
        <!-- this empty column is needed to make points & submit right aligned on large screens
        and left aligned on small screens -->
      </b-col>
    </b-row>
    <b-collapse
      :id="`solution-${problem.id}`"
      v-model="solutionVisible"
      class="print-hide"
      @show="trackViewSolution(analyticsProblemName)"
    >
      <b-card class="bg-light">
        <!-- v-if is here for lazy-loading images -->
        <div
          v-if="solutionVisible && problem.video_solution && youtubeEmbed"
          style="text-align: center"
        >
          <iframe
            width="560"
            height="315"
            :src="youtubeEmbed"
            frameborder="0"
            allowfullscreen
          />
        </div>

        <advanced-markdown v-if="solutionVisible" :source="problem.solution" />
      </b-card>
    </b-collapse>
  </DropZone>
</template>

<script>
import {
  AdvancedMarkdown,
  analytics,
  apiSubmits,
  CategoryBadge,
  DangerButton,
  LoadingSpinner,
  ThumbnailTooltip,
  utils,
} from "frontend-common";
import constants from "@/constants";
import DropZone from "@/components/utils/DropZone.vue";
import FavoriteStar from "@/components/utils/FavoriteStar.vue";
import ProblemSubmitUpload from "@/components/ProblemSubmitUpload.vue";

export default {
  name: "ProblemDetail",
  components: {
    CategoryBadge,
    DangerButton,
    FavoriteStar,
    LoadingSpinner,
    ProblemSubmitUpload,
    ThumbnailTooltip,
    AdvancedMarkdown,
    DropZone,
  },
  mixins: [apiSubmits, analytics],
  model: {
    prop: "modalVisible",
    event: "modalToggled",
  },
  props: {
    problem: {
      type: Object,
      required: true,
    },
    inSubmit: {
      type: Object,
      required: false,
      default: null,
    },
    allowUpload: {
      type: Boolean,
      required: true,
    },
    analyticsRoundName: {
      type: String,
      required: true,
    },
    highlightProblems: {
      type: Boolean,
      required: true,
    },
    round: {
      type: Object,
      required: true,
    },
    modalVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      utils,
      submit: null,
      submitLoaded: true,
      solutionVisible: false,
      uploadFiles: () => {},
      constants,
    };
  },
  computed: {
    analyticsProblemName() {
      return utils.fullProblemName(
        this.analyticsRoundName,
        this.problem.number,
        this.problem.name,
      );
    },
    dropZoneEnabled() {
      return (
        this.allowUpload &&
        this.$root.isAuthenticated &&
        this.$root.stateLoaded &&
        this.$root.state.user.organizer === null &&
        !this.modalVisible
      );
    },
    youtubeEmbed() {
      if (!this.problem.video_solution) {
        return "";
      }

      const url = new URL(this.problem.video_solution);
      const videoId = url.searchParams.get("v");

      if (!videoId) {
        if (this.problem.video_solution.indexOf("youtu.be") !== -1) {
          return "https://www.youtube.com/embed" + url.pathname;
        }
        return "";
      }

      return "https://www.youtube.com/embed/" + videoId;
    },
    isInteractive() {
      return !!this.problem.interactive;
    },
    visibleCategories() {
      return this.problem.categories.filter((c) => !c.hidden);
    },
  },
  watch: {
    "$root.isAuthenticated": "updateSubmits",
    "$root.stateLoaded": "updateSubmits",
    inSubmit: "reloadSubmitFromProp",
  },
  mounted() {
    this.reloadSubmitFromProp();
  },
  methods: {
    onSubmitUpload() {
      this.updateSubmits();
    },
    reloadSubmitFromProp() {
      this.submit = this.inSubmit;
    },
    updateSubmits() {
      if (!this.$root.isAuthenticated || !this.$root.stateLoaded) {
        return;
      }
      this.submitLoaded = false;
      this.apiSubmitMyLast(this.problem.id, this.$root.state.user.id)
        .then((response) => (this.submit = response))
        .finally(() => (this.submitLoaded = true));
    },
    downloadSubmit() {
      this.$root.downloadSubmit(
        this.submit.solution,
        this.round.number,
        this.problem.number,
      );
    },
    downloadEvaluation() {
      this.$root.downloadEvaluation(
        this.submit.evaluation.feedback,
        this.round.number,
        this.problem.number,
        this.analyticsProblemName,
      );
      this.submit.evaluation.seen = true;
      this.$root.silentlyLoadStatusDelayed();
    },
    tooltipText(problem, category) {
      if (problem.categories.some((category) => !category.recommended)) {
        return category.recommended
          ? "Povolená a odporúčaná kategória"
          : "Povolená kategória";
      }
      return null;
    },
    wrongFileTypes() {
      this.$root.dangerToast(
        "Niektoré súbory neboli správneho formátu! Akceptujeme len PDF, JPG a PNG!",
      );
    },
  },
};
</script>

<style scoped lang="scss">
.score {
  font-size: 25px; /* TODO remove this hardcoded value */
}

.icon-indicator::before {
  border-color: var(--success);
}
</style>
